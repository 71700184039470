/* eslint-disable spaced-comment */
<template>
  <h1 class="mt-3 mb-3">tsmc 常用英文單字測驗練習
    <small>Ver.2024 Oct</small>
  </h1>
  <div> ✏️ {{ allQues.filter((e) => e.state === undefined).length }}
    / ✔️ {{ allQues.filter((e) => e.state === true).length }}
    / ❌ {{ allQues.filter((e) => e.state === false).length }}
    / <span class="fw-bolder">🦉 {{ completed }}</span>
    <div v-show="isQuesRoundDone()">已回答所有的題目，請重新開始作答 😁</div>
  </div>

  <div class="question-block">
    <div> {{ quesObj.word }}</div>
  </div>

  <div class="answer-block">
    <ol>
    <li v-for="(a, index) in ansPools" :key="Math.random(index)" class="answer-option"
      v-bind:class="{ 'correct-answer': isAnsCorrect(index), 'wrong-answer': isAnsWrong(index)}">
      <span>{{a}}</span> <span v-if="showAns">{{ roundQues[index].word }}</span>
    </li>
    </ol>
  </div>

  <div class="answer-btns">
    <div v-for="(a,index) in ansPools"
      :key="Math.random(index)" v-on:click="checkAns(index)"
      class="btn btn-outline-secondary me-5 answer-btn"
      v-bind:class="{ 'disabled' : clickAns !== false}">
      {{index+1}}
    </div>
  </div>

  <hr/>

  <template v-if="isQuesRoundDone()">
    <div class="btn btn-danger me-3 ms-3 mb-2 d-md-inline-block d-block"
      v-on:click="loadAllQues()" >重新開始 (A)</div>
  </template>

  <div class="btn btn-success me-3 ms-3 mb-2 d-md-inline-block d-block"
    v-on:click="getQues()">下一題 (Enter)</div>

  <a class="btn btn-outline-primary me-3 ms-3 mb-2 d-md-inline-block d-block"
    href="https://sdwh.dev/about/" target="_blank">關於作者</a>
</template>

<script>
// @ is an alias to /src
import quesbank from '@/data/quesbank.json';
import Chance from 'chance';
// import successSound from '@/assets/success.mp3';
// import failSound from '@/assets/fail.mp3';

const chanceInstance = new Chance();

function updateStorage(completed) {
  localStorage.appSavings = JSON.stringify({ completed });
}

export default {
  name: 'Home',
  data() {
    return {
      showAns: false,
      clickAns: false,
      quesObj: null,
      roundQues: [],
      allQues: [],
      ansPools: [],
      completed: 0,
      quesbank,
    };
  },
  beforeMount() {
    if (localStorage.appSavings !== undefined) {
      const appSavings = JSON.parse(localStorage.appSavings);
      (
        {
          completed: this.completed,
        } = appSavings
      );
    }
    this.loadAllQues();
  },
  mounted() {
    window.addEventListener('keypress', (e) => {
      const inputKey = e.key.toString();
      // console.log(inputKey);
      if (inputKey === '1' || inputKey === '2' || inputKey === '3' || inputKey === '4') {
        if (this.clickAns === false) {
          this.checkAns(parseInt(inputKey, 10) - 1);
        }
      }
      if (inputKey === 'Enter' || inputKey === 'j' || inputKey === 'J') {
        this.getQues();
      }
      if (inputKey === 'a' || inputKey === 'A') {
        this.loadAllQues();
      }
    });
  },
  methods: {
    loadAllQues() {
      this.wrongs = new Set();
      Object.values(quesbank).forEach((e) => delete e.state);
      this.allQues = Object.values(quesbank);
      this.getQues();
    },
    getQues() {
      if (this.isQuesRoundDone() !== true) {
        this.showAns = false;
        this.clickAns = false;
        this.quesObj = chanceInstance.pickone(this.allQues.filter((e) => e.state === undefined));
        const otherQues = chanceInstance.pickset(this.allQues.filter((e) => e !== this.quesObj), 3);
        this.roundQues = chanceInstance.shuffle([this.quesObj, ...otherQues]);
        this.ansPools = this.roundQues.map((e) => e.meaning);
      }
    },
    checkAns(i) {
      if (this.isQuesRoundDone() !== true) {
        const isCorrect = this.ansPools[i] === this.quesObj.meaning;
        if (isCorrect) {
          this.completed += 1;
          this.quesObj.state = true;
        } else {
          this.quesObj.state = false;
        }

        this.clickAns = i;
        this.showAns = true;

        updateStorage(this.completed);
      }
    },
    isCorrect(i) {
      return this.ansPools[i] === this.quesObj.meaning;
    },
    isAnsCorrect(i) {
      return this.isCorrect(i) && this.showAns === true;
    },
    isAnsWrong(index) {
      return (this.isCorrect(index) === false) && this.showAns === true && index === this.clickAns;
    },
    isQuesRoundDone() {
      return Object.values(this.allQues).every((e) => e.state !== undefined);
    },
  },
};
</script>

<style scoped lang="scss">
$block-width : 50%;
$desktop: 'min-width: 1024px';
$pad: 'max-width: 1024px';
$mobile: 'max-width: 600px';

@mixin desktop{
  @media($desktop){
    @content;
  }
}
@mixin pad{
  @media($pad){
    @content;
  }
}
@mixin mobile{
  @media($mobile){
    @content;
  }
}

.correct-answer{
  color: green;
  font-weight: 600;
}

.wrong-answer{
  color: red;
}

.question-block{
  border: 2px solid teal;
  color: teal;
  border-radius: 3px;
  width: $block-width;
  display: block;
  margin: 20px auto;
  padding: 15px 15px;
  text-align: justify;
  font-size: 1.5rem;
  letter-spacing: 1px;
  @include pad{
    width: 95%;
  }
  @include mobile{
    width: 95%;
    font-size: 1.25rem;
  }
}

.answer-block{
  background-color: beige;
  border: 2px darksalmon dashed;
  color: black;
  padding: 5px;
  font-size: 1.5rem;
  width: $block-width;
  text-align: left;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 3px;
  @include pad{
    width: 95%;
  }
  @include mobile{
    width: 95%;
    font-size: 1rem;
  }
}

.answer-btns{
  margin-top: 20px;
  @include mobile{
    margin-top: 5px;
  }
}

.answer-btn{
  font-size: 1.25rem;
  padding: .5rem 1rem;
  @include mobile{
    margin-right: 25px !important;
    margin-left: 25px !important;
    font-size: 1rem;
    padding: .5rem 1rem;
  }
}

.question-ref{
  font-size: 1.25rem;
  color: black;
  text-align: right;

  @include mobile{
    font-size: 0.8rem;
  }
}

small {
  font-size: 0.5em;
}
</style>
